<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">后台管理系统</div>
      
      <el-form
        :model="param"
        :rules="rules"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input
            v-model="param.username"
            placeholder="username"
          >
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="password"
            v-model="param.password"
            @keyup.enter="submitForm()"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button
            type="primary"
            @click="submitForm()"
          >登录</el-button>
        </div>
        <p class="login-tips">Tips : 浏览器可以帮忙记住账号和密码</p>
        <a style="color: white;display: block;text-align: center;" href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">浙ICP备2021020956号</a>
      </el-form>
    </div>
    <div class="bottom-logo"><img style="width:10%;" src="http://wza.u.zjqichuang.com/static/upload/image/20210526/1622017611872365.png"></div>
  </div>
</template>

<script>
import api from "@/api/index";

export default {
  data() {
    return {
      logo:require('@/assets/img/logoBlack.png'),
      param: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {
    submitForm() {
      let that = this;
      //   that.$message.success("登录成功");
      //   localStorage.setItem("ms_username", this.param.username);
      //   this.$router.push("/");
      that.$refs.login.validate((valid) => {
        if (valid) {
          api
            .authLogin({
              username: that.param.username,
              password: that.param.password,
            })
            .then((res) => {
              if (res.status == 200) {
                window.localStorage.setItem("token", res.data.token);
                window.localStorage.setItem("ms_username", res.data.username);
                that.$router.push("/");
                that.$message.success("登录成功");
              }
            });
        } else {
          console.log("登录失败");
        }
      });
    },
  },
};
</script>

<style scoped>
.bottom-logo{
  position: fixed;
  bottom: 20px;
  width: 100%;
  
}
.bottom-logo>img{
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translate(-50%,0%);
}
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>